import { Redirect } from "@reach/router";
import { skipToken } from "@reduxjs/toolkit/query";
import { formatISO, isAfter, parseISO } from "date-fns";
import { navigate } from "gatsby";
import { type ComponentPropsWithoutRef, useCallback, useMemo } from "react";

import { useUserGetQuery } from "../../../api";
import { LoadingScreen } from "../../../components";
import { getSessionRoute, sessionSignOut } from "../../../session";
import { useAppDispatch } from "../../../store";
import {
  type CurriculumLessonId,
  type CurriculumSwapUserId,
  curriculumDateSchema,
  CurriculumPatchLessonOptions_Patch,
  useCurriculumApiGetLessonsQuery,
  useCurriculumApiPatchLessonMutation,
} from "../../api";
import type { CurriculumClass } from "../../classes/createCurriculumClass";
import { CurriculumClass1 } from "../../classes/CurriculumClass1";
import { CurriculumClass2 } from "../../classes/CurriculumClass2";
import { CurriculumClass3 } from "../../classes/CurriculumClass3";
import { CurriculumClass4 } from "../../classes/CurriculumClass4";
import { CurriculumClass5 } from "../../classes/CurriculumClass5";
import { CurriculumClass6 } from "../../classes/CurriculumClass6";

export type CurriculumClassPageControllerProps = {
  readonly lessonId: CurriculumLessonId;
  readonly locationPathname: string;
};

type CreateClassMap<
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Mapping extends Record<string, CurriculumClass<any, any, any>>,
> = Mapping;

type ClassMap = CreateClassMap<{
  [CurriculumClass1.classKey]: typeof CurriculumClass1;
  [CurriculumClass2.classKey]: typeof CurriculumClass2;
  [CurriculumClass3.classKey]: typeof CurriculumClass3;
  [CurriculumClass4.classKey]: typeof CurriculumClass4;
  [CurriculumClass5.classKey]: typeof CurriculumClass5;
  [CurriculumClass6.classKey]: typeof CurriculumClass6;
}>;

const classMap: ClassMap = {
  [CurriculumClass1.classKey]: CurriculumClass1,
  [CurriculumClass2.classKey]: CurriculumClass2,
  [CurriculumClass3.classKey]: CurriculumClass3,
  [CurriculumClass4.classKey]: CurriculumClass4,
  [CurriculumClass5.classKey]: CurriculumClass5,
  [CurriculumClass6.classKey]: CurriculumClass6,
};

export function CurriculumClassPageController({
  lessonId,
  locationPathname,
}: CurriculumClassPageControllerProps) {
  const dispatch = useAppDispatch();
  const handleSignOutClick = useCallback(async () => {
    dispatch(sessionSignOut({ locationPathname }));
  }, [dispatch, locationPathname]);

  const userGetQuery = useUserGetQuery();
  const getLessonsQuery = useCurriculumApiGetLessonsQuery(
    userGetQuery.data?.user_id
      ? (userGetQuery.data?.user_id as CurriculumSwapUserId)
      : skipToken,
  );

  const [triggerPatchLesson] = useCurriculumApiPatchLessonMutation();

  const isViewable = useMemo(() => {
    if (!getLessonsQuery.data) return false;

    const lesson = getLessonsQuery.data.find(
      (lesson) => lesson.lessonId === lessonId,
    );
    if (!lesson) return false;

    const isComplete = lesson.dateComplete !== null;
    const isFuture = isAfter(parseISO(lesson.dateAvailable), new Date());

    return !isComplete && !isFuture;
  }, [getLessonsQuery.data, lessonId]);

  if (!getLessonsQuery.data) return <LoadingScreen />;

  const Class = classMap[lessonId as keyof ClassMap] as
    | ClassMap[keyof ClassMap]
    | undefined;

  if (!Class || !isViewable) {
    return <Redirect to="/curriculum" noThrow />;
  }

  const handleExitClick = () => {
    navigate(getSessionRoute("curriculum"));
  };

  const handleSubmit = async (
    values: Parameters<
      Extract<
        ComponentPropsWithoutRef<ClassMap[keyof ClassMap]>,
        { startAt?: undefined }
      >["onSubmit"]
    >[0],
  ) => {
    // TODO: Add the ability to submit the progress per screen.
    const patches: [
      CurriculumPatchLessonOptions_Patch,
      ...CurriculumPatchLessonOptions_Patch[],
    ] = [
      {
        key: "completedDate",
        value: curriculumDateSchema.parse(formatISO(new Date())),
      },
      {
        key: "reportedWeeklyActivityInMinutes",
        value: values.master.masterScreen1.physicalActivityMinutes,
      },
      {
        key: "reportedWeeklyBodyWeightInLbs",
        value: values.master.masterScreen2.todaysWeight,
      },
      {
        key: "startDate",
        value: curriculumDateSchema.parse(formatISO(new Date())),
      },
    ];

    await triggerPatchLesson({
      lessonId,
      patches,
      swapUserId: userGetQuery.data?.user_id as CurriculumSwapUserId,
    }).unwrap();
  };

  return (
    <Class
      onExitClick={handleExitClick}
      onSignOutClick={handleSignOutClick}
      onSubmit={handleSubmit}
    />
  );
}
